<template>
  <div>
    <section class="flex flex-col md:flex-row h-screen items-center">
      <div
        class="bg-indigo-600 hidden lg:block w-full md:w-1/2 xl:w-2/3 h-screen"
      >
        <img
          src="https://source.unsplash.com/collection/3851068"
          alt=""
          class="w-full h-full object-cover"
        />
      </div>

      <div
        class="bg-white w-full md:max-w-md lg:max-w-full md:mx-auto md:mx-0 md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12
        flex items-center justify-center"
      >
        <div class="w-full h-100">
          <h1 class="text-xl md:text-2xl font-bold leading-tight mt-12">
            Log in to your account
          </h1>

          <form class="mt-6">
            <div>
              <label class="block text-gray-700 text-left">Email Address</label>
              <input
                v-model.trim="email"
                type="email"
                placeholder="Enter Email Address"
                class="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                autofocus
                autocomplete
                required
              />
            </div>

            <div class="mt-4">
              <label class="block text-gray-700 text-left">Password</label>
              <input
                v-model.trim="password"
                type="password"
                placeholder="Enter Password"
                minlength="8"
                class="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500
                focus:bg-white focus:outline-none"
                required
              />
            </div>

            <button
              @click.prevent="login"
              type="submit"
              class="w-full block bg-indigo-500 hover:bg-indigo-400 focus:bg-indigo-400 text-white font-semibold rounded-lg
              px-4 py-3 mt-6"
            >
              Log In
            </button>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    async login() {

      const formData = {
        email: this.email,
        password: this.password,
      }
      console.log(formData)
      await this.$store.dispatch("login", formData);
    },
  },
};
</script>

<style></style>
